import React from "react";
import './footer.css';
import { BsFacebook } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";

const Footer = () => {
    return (
      <footer>
        <a href="#" className="footer__logo">
          Titanium
        </a>

        <ul className="permalinks">
          <li>
            <a href="#">Accueil</a>
          </li>
          <li>
            <a href="#pro">Gammes</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>

        <div className="footer__socials">
            <a
              href="https://www.facebook.com/profile.php?id=100011195713319"
              target="_blank"
              rel="noopener noreferrer"
              alt="facebook"
            >
              <BsFacebook />
            </a>
            <a
              href="https://www.instagram.com/titaniumboats/"
              target="_blank"
              rel="noopener noreferrer"
              alt="instagram"
            >
              <BsInstagram />
            </a>
          </div>

        <div className="footer__copyright">
          <small>&copy; Bateauxtitanium. All rights reserved.</small>
        </div>
      </footer>
    );
  };
  
  export default Footer;